import { takeLatest } from 'redux-saga/effects';

/* ---------------------------import-worker-sagas---------------------------*/
import {
  targetMarketSaga, getSubcodesSaga, getBrandListSaga, getCategoryListSaga, getProductBasicDataSaga, getLicensedSubcodesSaga,
} from '../worker-sagas/uncertified/create-uncertified-product-saga';
import { getTemplateSaga, getProductDataSaga } from '../worker-sagas/uncertified/edit-uncertified-product-saga';
import {
  getProductListSaga, getDynamicCategoryListSaga, getAssetTypeListSaga, getDynamicStatusSaga,
} from '../worker-sagas/uncertified/uncertified-product-saga';
import { getLanguagesListSaga, getUpdatedLanguageSaga, getUserLanguagesSaga } from '../worker-sagas/uncertified/languageSaga';
import { handleGetUnitsSaga, handleQualifiersSaga } from '../worker-sagas/uncertified/structuredNutritionSagas';
import {
  getProductImagesSaga, getProductDocumentsSaga, getDocumentTypeSaga, getImageShotTypeSaga, getAssetExistsSaga, getDeleteAssetSaga,
} from '../worker-sagas/uncertified/uncertified-product-asset-saga';
import { getCommentsListSaga } from '../worker-sagas/uncertified/product-comments-saga';
import { getUserDetailsSaga, getNotificationsSaga, getServiceMessageSaga } from '../worker-sagas/uncertified/get-user-details-saga';
import { getMailSaga, getConnectPlusSaga } from '../worker-sagas/uncertified/get-connect-plus-saga';
import {
  getSdeRecentEditedProductsSaga, getLatestSdePendingApprovalProductsSaga, getCountProductSaga, getMarketingContentSaga, getCompletenessReportSaga, getRSCCountSaga, getRecentEditedCertifiedProductSaga, getLatestPendingApprovalCertifiedProductsSaga,
} from '../worker-sagas/uncertified/homePageSaga';
import { getCertifiedProductSaga, getCertifiedCategoryFilterListSaga, getCertifiedStatusFilterSaga } from '../worker-sagas/certified/CertifiedProductSaga';
import {
  getCertifiedProductImagesSaga, getCertifiedProductDocumentsSaga, getPostPublicationProductDocumentsSaga, getPostPublicationProductImagesSaga,
} from '../worker-sagas/certified/CertifiedProductAssetSaga';
import { getCertifiedProductListSaga, getCertifiedProductAssetTypeListSaga, handleGetTargetUsageSaga } from '../worker-sagas/certified/CertifiedListSaga';
import { getListFilterSaga } from '../worker-sagas/uncertified/ListGlobelSaga';
import { getSubcodeSaga, getBasicTempSaga } from '../worker-sagas/nbb/create';
import {
  handleProductSearchSaga, getAdvanceFilterDropdownSaga, getCINLogMessageSaga, handleGetCicLogMsgSaga, handleGetNBBTargetMarketSaga, handleGetPublishStatusLogSaga,
} from '../worker-sagas/nbb/list';
import { getAwaitingBrandbankCapturedListSaga, getAwaitingApprovalViewProductDataSaga, getAwaitingBrandbankCapturedFilterListSaga } from '../worker-sagas/awaiting-brandbank-captured-data-review/AwaitingBrandbankCapturedDataReviewList';
import { getAwaitingBrandbankCapturedImagesSaga, getAwaitingBrandbankCapturedDocumentsSaga } from '../worker-sagas/awaiting-brandbank-captured-data-review/AwaitingBrandBankCapturedDataAsset';
import {
  getHybridCompletenessReportSaga, getHybridCountProductSaga, getHybridProductChannelCountSaga, getRecentEditedNbbProductSaga, getHybridBrandbankCapturedDataSaga, getHybridProductValidationCountSaga, getRSCProductCountSaga, getCountByCertificationSaga, getNbbProductImageTypeListSaga,
} from '../worker-sagas/nbb/homePageSaga';
import { getSSAttibutesSaga } from '../worker-sagas/nbb/edit';

// -------------------------------------reports-watcher---------------------------------------------
import { getPublicationReportSaga } from '../worker-sagas/reports/publication-summary';
import { getPublicationDetailReportSaga } from '../worker-sagas/reports/publication-detail';
import { getMergeDetailReportSaga } from '../worker-sagas/reports/merge-detail';
import {
  handleGetCodeListSaga, handleGetValidationSaga, getValidationLabelSaga, getGpcStaticSaga, getGroupsStaticSaga,
} from '../worker-sagas/nbb/staticDataSaga';

/** ------ CH+ -------- */
import { getContentHealthAndRankRquestSaga, getProductRecalculateScore } from '../worker-sagas/certified/ContentHealthSaga';
// -------------------------image topup watcher---------------------------------------
import { getProductTopupLayoutSaga, getUserUploadedAssetSaga } from '../worker-sagas/certified/ProductImageTopup';
// ----------------------------flexible extract-------------------------------------------
import { getCertifiedAttributeDataSaga } from '../worker-sagas/flexible-extract/get-attribute-data';
import {getImageConfigurationSaga} from '../worker-sagas/flexible-extract/get-image-configuration';
/* ---------------------------import-actions---------------------------*/
import {
  GET_TARGET_MARKET_REQUEST,
  GET_SUBCODES_REQUEST,
  GET_BRANDS_REQUEST,
  GET_TEMPLATE_REQUEST,
  GET_PRODUCT_DATA_REQUEST,
  GET_UNCERTIFIED_PRODUCT_LIST_REQUEST,
  GET_CATEGORY_REQUEST,
  GET_UNCERTIFIED_PRODUCT_IMAGES_REQUEST,
  GET_UNCERTIFIED_PRODUCT_DOCUMENTS_REQUEST,
  GET_DOCUMENT_TYPE_REQUEST,
  GET_IMAGE_TYPE_REQUEST,
  GET_ASSET_EXISTS_REQUEST,
  GET_LANGUAGE_LIST_REQUEST,
  GET_LANGUAGE_UPDATE_REQUEST,
  GET_DELETE_ASSET_REQUEST,
  GET_D_CATEGORY_REQUEST,
  GET_UNCERTIFIED_STATUS_REQUEST,
  GET_UNITS_REQUEST,
  GET_QUALIFIERS_REQUEST,
  GET_PRODUCT_BASIC_DATA_REQUEST,
  GET_ASSET_TYPE_BY_SELECTED_PRODUCT_REQUEST,
  GET_COMMENTS_REQUEST,
  GET_USER_DETAILS_REQUEST,
  GET_CONNECT_PLUS_REQUEST,
  GET_SDE_RECENT_EDITED_PRODUCTS_REQUEST,
  GET_SDE_LATEST_PENDING_APPROVAL_REQUEST,
  GET_COUNT_PRODUCT_REQUEST,
  GET_USER_LANGUAGE_REQUEST,
  GET_CERTIFIED_PRODUCT_DATA_REQUEST,
  GET_CERTIFIED_PRODUCT_IMAGES_REQUEST,
  GET_CERTIFIED_PRODUCT_DOCUMENTS_REQUEST,
  GET_CERTIFIED_PRODUCT_LIST_REQUEST,
  GET_MARKETING_CONTENT_REQUEST,
  GET_CERTIFIED_PRODUCT_ASSET_TYPE_REQUEST,
  GET_CERTIFIED_CATEGORY_REQUEST,
  GET_CERTIFIED_STATUS_REQUEST,
  GET_NOTIFICATIONS,
  GET_CONNECT_PLUS_MAIL_REQUEST,
  GET_LICENSED_SUBCODES_REQUEST,
  GET_SERVICE_MESSAGE_REQUEST,
  GET_COMPLETENESS_REPORT_REQUEST,
  HANDLE_GET_DASHBOARD_RSC_COUNT,
  GET_CERTIFIED_RECENT_EDITED_PRODUCTS_REQUEST,
  GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_REQUEST,
  GET_TARGET_USAGE_OPTION_REQUEST,
  GET_FILTER_DATA_REQUEST,
  GET_AWAITING_BRANDBANK_CAPTURED_LIST_REQUEST,
  GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_REQUEST,
  GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_REQUEST,
  GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_REQUEST,
  GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_REQUEST,
  GET_PUBLICATION_SUMMARY_REPORTS,
  GET_PUBLICATION_DETAIL_REPORTS,
  GET_MERGE_DETAIL_REPORT,
  GET_POST_PUBLICATION_IMAGES_REQUEST,
  GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_REQUEST,
  /* ----CH+----*/
  GET_CH_PLUS_REPORT_REQUEST,
  GET_PRODUCT_RECALCULATE_SCORE,
  /* ------CH+ Ends */

  GET_PRODUCT_TOPUP_LAYOUTS,
  GET_USER_UPLOADED_ASSET_REQUEST,
  GET_CERTIFIED_ATTRIBUTE_DATA_REQUEST,
  GET_IMAGE_CONFIGURATIONS_REQUEST
} from '../../actions/Actions';

// -------------------------------------nbb-props-action-----------------------------------------
import {
  GET_SUBCODE_REQUEST,
  GET_PRODUCT_SEARCH_REQUEST,
  GET_ADVANCE_FILTER_DROPDOWN_REQUEST,
  GET_BASIC_TEMPLATE_DATA_REQUEST,
  GET_CIN_LOG_MSG_REQUEST,
  GET_CIC_LOG_MSG_REQUEST,
  GET_PUBLISH_STATUS_LOG_REQUEST,
  GET_NBB_TARGETMARKET_LIST_REQUEST,
  GET_HYBRID_COMPLETENESS_SCORE_REQUEST,
  GET_HYBRID_PRODUCT_COUNT_BY_STATUS_REQUEST,
  GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_REQUEST,
  GET_NBB_RECENT_EDITED_PRODUCTS_REQUEST,
  GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_REQUEST,
  GET_HYBRID_PRODUCT_VALIDATION_COUNT_REQUEST,
  GET_DASHBOARD_RSC_PRODUCT_COUNT_REQUEST,
  GET_DASHBOARD_COUNT_BY_CERTIFICATION_REQUEST,
  GET_NBB_PRODUCT_IMAGE_TYPE_REQUEST,
  GET_SUPPLIER_SPECIFIC_ATTRIBUTE_REQUEST,
  GET_CODELIST_REQUEST,
  GET_VALIDATION_DATA_REQUEST,
  GET_VALIDATION_LABEL_REQUEST,
  GET_GPC_STATIC_FILE_REQUEST,
  GET_GROUPS_REQUEST,

} from '../../actions/nbb';

export function* watcherSaga() {
  yield takeLatest(GET_TARGET_MARKET_REQUEST, targetMarketSaga);
  yield takeLatest(GET_SUBCODES_REQUEST, getSubcodesSaga);
  yield takeLatest(GET_BRANDS_REQUEST, getBrandListSaga);
  yield takeLatest(GET_TEMPLATE_REQUEST, getTemplateSaga);
  yield takeLatest(GET_PRODUCT_DATA_REQUEST, getProductDataSaga);
  yield takeLatest(GET_UNCERTIFIED_PRODUCT_LIST_REQUEST, getProductListSaga);
  yield takeLatest(GET_CATEGORY_REQUEST, getCategoryListSaga);
  yield takeLatest(GET_CERTIFIED_STATUS_REQUEST, getCertifiedStatusFilterSaga);
  yield takeLatest(GET_UNCERTIFIED_STATUS_REQUEST, getDynamicStatusSaga);
  yield takeLatest(GET_UNCERTIFIED_PRODUCT_IMAGES_REQUEST, getProductImagesSaga);
  yield takeLatest(GET_UNCERTIFIED_PRODUCT_DOCUMENTS_REQUEST, getProductDocumentsSaga);
  yield takeLatest(GET_DOCUMENT_TYPE_REQUEST, getDocumentTypeSaga);
  yield takeLatest(GET_IMAGE_TYPE_REQUEST, getImageShotTypeSaga);
  yield takeLatest(GET_ASSET_EXISTS_REQUEST, getAssetExistsSaga);
  yield takeLatest(GET_LANGUAGE_LIST_REQUEST, getLanguagesListSaga);
  yield takeLatest(GET_LANGUAGE_UPDATE_REQUEST, getUpdatedLanguageSaga);
  yield takeLatest(GET_DELETE_ASSET_REQUEST, getDeleteAssetSaga);
  yield takeLatest(GET_D_CATEGORY_REQUEST, getDynamicCategoryListSaga);
  yield takeLatest(GET_UNITS_REQUEST, handleGetUnitsSaga);
  yield takeLatest(GET_QUALIFIERS_REQUEST, handleQualifiersSaga);
  yield takeLatest(GET_PRODUCT_BASIC_DATA_REQUEST, getProductBasicDataSaga);
  yield takeLatest(GET_ASSET_TYPE_BY_SELECTED_PRODUCT_REQUEST, getAssetTypeListSaga);
  yield takeLatest(GET_USER_DETAILS_REQUEST, getUserDetailsSaga);
  yield takeLatest(GET_CONNECT_PLUS_REQUEST, getConnectPlusSaga);
  yield takeLatest(GET_COMMENTS_REQUEST, getCommentsListSaga);
  yield takeLatest(GET_SDE_RECENT_EDITED_PRODUCTS_REQUEST, getSdeRecentEditedProductsSaga);
  yield takeLatest(GET_SDE_LATEST_PENDING_APPROVAL_REQUEST, getLatestSdePendingApprovalProductsSaga);
  yield takeLatest(GET_COUNT_PRODUCT_REQUEST, getCountProductSaga);
  yield takeLatest(GET_USER_LANGUAGE_REQUEST, getUserLanguagesSaga);
  yield takeLatest(GET_CERTIFIED_PRODUCT_DATA_REQUEST, getCertifiedProductSaga);
  yield takeLatest(GET_CERTIFIED_PRODUCT_IMAGES_REQUEST, getCertifiedProductImagesSaga);
  yield takeLatest(GET_CERTIFIED_PRODUCT_DOCUMENTS_REQUEST, getCertifiedProductDocumentsSaga);
  yield takeLatest(GET_POST_PUBLICATION_IMAGES_REQUEST, getPostPublicationProductImagesSaga);
  yield takeLatest(GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_REQUEST, getPostPublicationProductDocumentsSaga);
  yield takeLatest(GET_CERTIFIED_PRODUCT_LIST_REQUEST, getCertifiedProductListSaga);
  yield takeLatest(GET_MARKETING_CONTENT_REQUEST, getMarketingContentSaga);
  yield takeLatest(GET_CERTIFIED_PRODUCT_ASSET_TYPE_REQUEST, getCertifiedProductAssetTypeListSaga);
  yield takeLatest(GET_CERTIFIED_CATEGORY_REQUEST, getCertifiedCategoryFilterListSaga);
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(GET_CONNECT_PLUS_MAIL_REQUEST, getMailSaga);
  yield takeLatest(GET_LICENSED_SUBCODES_REQUEST, getLicensedSubcodesSaga);
  yield takeLatest(GET_SERVICE_MESSAGE_REQUEST, getServiceMessageSaga);
  yield takeLatest(GET_COMPLETENESS_REPORT_REQUEST, getCompletenessReportSaga);
  yield takeLatest(HANDLE_GET_DASHBOARD_RSC_COUNT, getRSCCountSaga);
  yield takeLatest(GET_CERTIFIED_RECENT_EDITED_PRODUCTS_REQUEST, getRecentEditedCertifiedProductSaga);
  yield takeLatest(GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_REQUEST, getLatestPendingApprovalCertifiedProductsSaga);
  yield takeLatest(GET_TARGET_USAGE_OPTION_REQUEST, handleGetTargetUsageSaga);
  yield takeLatest(GET_FILTER_DATA_REQUEST, getListFilterSaga);

  // ------------------------------------NBB-Product---------------------------------------------
  yield takeLatest(GET_SUBCODE_REQUEST, getSubcodeSaga);
  yield takeLatest(GET_PRODUCT_SEARCH_REQUEST, handleProductSearchSaga);
  yield takeLatest(GET_ADVANCE_FILTER_DROPDOWN_REQUEST, getAdvanceFilterDropdownSaga);
  yield takeLatest(GET_BASIC_TEMPLATE_DATA_REQUEST, getBasicTempSaga);
  yield takeLatest(GET_CIN_LOG_MSG_REQUEST, getCINLogMessageSaga);
  yield takeLatest(GET_CIC_LOG_MSG_REQUEST, handleGetCicLogMsgSaga);
  yield takeLatest(GET_NBB_TARGETMARKET_LIST_REQUEST, handleGetNBBTargetMarketSaga);
  yield takeLatest(GET_PUBLISH_STATUS_LOG_REQUEST, handleGetPublishStatusLogSaga);
  yield takeLatest(GET_HYBRID_COMPLETENESS_SCORE_REQUEST, getHybridCompletenessReportSaga);
  yield takeLatest(GET_HYBRID_PRODUCT_COUNT_BY_STATUS_REQUEST, getHybridCountProductSaga);
  yield takeLatest(GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_REQUEST, getHybridProductChannelCountSaga);
  yield takeLatest(GET_NBB_RECENT_EDITED_PRODUCTS_REQUEST, getRecentEditedNbbProductSaga);
  yield takeLatest(GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_REQUEST, getHybridBrandbankCapturedDataSaga);
  yield takeLatest(GET_HYBRID_PRODUCT_VALIDATION_COUNT_REQUEST, getHybridProductValidationCountSaga);
  yield takeLatest(GET_DASHBOARD_RSC_PRODUCT_COUNT_REQUEST, getRSCProductCountSaga);
  yield takeLatest(GET_DASHBOARD_COUNT_BY_CERTIFICATION_REQUEST, getCountByCertificationSaga);
  yield takeLatest(GET_NBB_PRODUCT_IMAGE_TYPE_REQUEST, getNbbProductImageTypeListSaga);
  yield takeLatest(GET_SUPPLIER_SPECIFIC_ATTRIBUTE_REQUEST, getSSAttibutesSaga);

  // --------------------------Awaiting-Brandbank-Captured-Data-Review-----------------------------
  yield takeLatest(GET_AWAITING_BRANDBANK_CAPTURED_LIST_REQUEST, getAwaitingBrandbankCapturedListSaga);
  yield takeLatest(GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_REQUEST, getAwaitingApprovalViewProductDataSaga);
  yield takeLatest(GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_REQUEST, getAwaitingBrandbankCapturedImagesSaga);
  yield takeLatest(GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_REQUEST, getAwaitingBrandbankCapturedDocumentsSaga);
  yield takeLatest(GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_REQUEST, getAwaitingBrandbankCapturedFilterListSaga);

  // ----------------------------reports------------------------------------
  yield takeLatest(GET_PUBLICATION_SUMMARY_REPORTS, getPublicationReportSaga);
  yield takeLatest(GET_PUBLICATION_DETAIL_REPORTS, getPublicationDetailReportSaga);
  yield takeLatest(GET_MERGE_DETAIL_REPORT, getMergeDetailReportSaga);

  // ----------------------static files-----------------------------
  yield takeLatest(GET_CODELIST_REQUEST, handleGetCodeListSaga);
  yield takeLatest(GET_VALIDATION_DATA_REQUEST, handleGetValidationSaga);
  yield takeLatest(GET_VALIDATION_LABEL_REQUEST, getValidationLabelSaga);
  yield takeLatest(GET_GPC_STATIC_FILE_REQUEST, getGpcStaticSaga);
  yield takeLatest(GET_GROUPS_REQUEST, getGroupsStaticSaga);

  /** ------- CH+ ------ */
  yield takeLatest(GET_CH_PLUS_REPORT_REQUEST, getContentHealthAndRankRquestSaga);
  yield takeLatest(GET_PRODUCT_RECALCULATE_SCORE, getProductRecalculateScore);
  // ----------------------------certified image topup-------------------------------------
  yield takeLatest(GET_PRODUCT_TOPUP_LAYOUTS, getProductTopupLayoutSaga);
  yield takeLatest(GET_USER_UPLOADED_ASSET_REQUEST, getUserUploadedAssetSaga);
  // -----------------------flexible extract-------------------------------
  yield takeLatest(GET_CERTIFIED_ATTRIBUTE_DATA_REQUEST, getCertifiedAttributeDataSaga);
  yield takeLatest(GET_IMAGE_CONFIGURATIONS_REQUEST, getImageConfigurationSaga);
}
