import { call, put } from 'redux-saga/effects';
import { GET_IMAGE_CONFIGURATIONS_SUCCESS, GET_IMAGE_CONFIGURATIONS_FAILURE } from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
import { GET_IMAGE_CONFIGURATIONS } from '../../../../constant/Apis';
import { getDcTime } from '../../../../Utils/utils';

export async function getImageConfiguration(url, token) {
    return axiosApiCall.get(url, {
        params: {
            _dc: getDcTime()
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export function* getImageConfigurationSaga(action) {
    try {
        const token = yield call(getIdToken, action.data.auth0);
        const response = yield call(getImageConfiguration, GET_IMAGE_CONFIGURATIONS, token);
        let configData = response.data;
        let imageDimensionOpt = [
          {
            label: 'Original (transparent where available)',
            options: [{ label: '2400 x 2400 (or best available)', value: 'transparent' }]
          },
          {
            label: 'Custom',
            options: [{ label: 'Create custom', value: 'custom' }]
          },
          {
            label: 'Saved Presets',
            options: configData?.Preset?.map(item => {
              return {
                label: item?.configLabel,
                value: item?.id,
                configValue: item?.configValue
              }
            })
          }
        ];

        let data={configData,imageDimensionOpt};

        yield put({ type: GET_IMAGE_CONFIGURATIONS_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_IMAGE_CONFIGURATIONS_FAILURE, error });
    }
}
