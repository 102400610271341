import { call, put } from 'redux-saga/effects';
import { GET_CERTIFIED_ATTRIBUTE_DATA_SUCCESS, GET_CERTIFIED_ATTRIBUTE_DATA_FAILURE } from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
import { EXPORT_GET_ATTRIBUTE_DATA } from '../../../../constant/Apis';

export async function getCertifiedAttributeData(url, data, token) {
    return axiosApiCall.post(url, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export function* getCertifiedAttributeDataSaga(action) {
    try {
        const token = yield call(getIdToken, action.data.auth0);
        let payload = {
            targetMarkets: action.data.targetMarkets,
            subcodes: action.data.subcodes
        }
        const response = yield call(getCertifiedAttributeData, EXPORT_GET_ATTRIBUTE_DATA, payload, token);
        let templateData = response.data?.data;
        let filterResponseData = [];
        if (!action.data.isEnableConnectMaxCustomization) {
            filterResponseData = templateData?.map(item => {
                if (item.label === 'Asset URLs') {
                    let filterChildren = item.children.filter(v => v.id !== 'connect_max_images');
                    item.children = filterChildren;
                }
                return item;
            });
        } else {
            filterResponseData = templateData;
        }
        const data = filterResponseData;
        yield put({ type: GET_CERTIFIED_ATTRIBUTE_DATA_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_CERTIFIED_ATTRIBUTE_DATA_FAILURE, error });
    }
}
